/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'checkbox-on': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.451.71C13.256.243 11.11.02 9 0 6.89.019 4.744.243 3.568.692 1.066 1.664.488 4.43.488 4.43a18.896 18.896 0 00-.486 4.56c-.019 1.421.093 3.01.485 4.58 0 0 .579 2.766 3.08 3.738 1.177.449 3.324.673 5.433.692 2.11-.019 4.256-.224 5.432-.692 2.502-.972 3.08-3.738 3.08-3.738.374-1.57.505-3.159.486-4.56.019-1.421-.093-3.01-.467-4.561 0 0-.579-2.767-3.08-3.739z" _fill="#6D62FC"/><mask id="svgicon_checkbox-on_a" maskUnits="userSpaceOnUse" x="3" y="5" width="12" height="8"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M14.024 5.984a.5.5 0 01-.008.707l-5.793 5.667a.5.5 0 01-.692.006L3.99 9.031a.5.5 0 01.685-.728l3.191 3.004 5.45-5.331a.5.5 0 01.707.008z" _fill="#000"/></mask><g mask="url(#svgicon_checkbox-on_a)"><path pid="2" _fill="#fff" d="M3 3h12v12H3z"/></g>'
  }
})
